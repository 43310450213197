



















































import Vue from "vue";
import CitaEventoService, {
  GetCitaEventoDto
} from "@/api/evento/CitaEventoService";
import { AxiosError } from "axios";
export default Vue.extend({
  data() {
    return {
      dias: [] as GetCitaEventoDto[],
      horarios: [] as GetCitaEventoDto[],
      picker: ""
    };
  },
  methods: {
    loadDiasDisponibles() {
      CitaEventoService.getDiasDisponibles(this.$route.params.eventoID).then(
        response => {
          this.dias = response;
          if (this.dias.length > 0) {
            this.picker = String(this.dias[0].fecha).split("T")[0];
          }
        }
      );
    },
    loadHorarios() {
      CitaEventoService.getHorariosDisponibles(
        this.$route.params.eventoID
      ).then(response => {
        this.horarios = response;
      });
    },
    agendar(dto: any) {
      CitaEventoService.agendar(this.$route.params.eventoID, dto)
        .then(() => {
          //
          this.$swal({
            icon: "success",
            title: "Registro Cita",
            text: `Exitoso. Su horario es ${new Date(
              dto.fecha
            ).toLocaleString()}`
          }).then(() => {
            this.$router.push("/account/mis-citas");
          });
          //
        })
        .catch((error: AxiosError) => {
          //
          let mensaje = "No se pudo agendar, reintente luego";
          if (error.response?.data && error.response.data.message) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 1500,
            icon: "error",
            title: "Registro Cita",
            text: mensaje
          });
          //
        });
    },
    allowedDates(val: string) {
      const found = this.dias.find(
        e => String(e.fecha).split("T")[0] === String(val)
      );
      return found ? true : false;
    }
  },
  created() {
    this.loadDiasDisponibles();
    this.loadHorarios();
  },
  computed: {
    fechaMin(): string {
      if (this.dias.length > 0) {
        return String(this.dias[0].fecha).split("T")[0];
      }
      return "";
    },
    fechaMax(): string {
      if (this.dias.length > 0) {
        return String(this.dias[this.dias.length - 1].fecha).split("T")[0];
      }
      return "";
    },
    fechasPorDias(): GetCitaEventoDto[] {
      if (this.picker) {
        const fecha = new Date(this.picker);
        fecha.setDate(fecha.getDate() + 1);
        return this.horarios.filter(
          v =>
            new Date(String(v.fecha)).toLocaleDateString() ==
            fecha.toLocaleDateString()
        );
      }
      return [];
    }
  }
});
