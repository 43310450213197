






















import Vue from "vue";
import CitaEventoService, {
  GetReservaCitaEventoDto
} from "@/api/evento/CitaEventoService";
export default Vue.extend({
  data() {
    return {
      headers: [
        { text: "#", value: "id" },
        { text: "Tipo", value: "horario.displayTipo", align: "center" },
        { text: "Módulo", value: "horario.modulo.nombre", align: "center" },
        { text: "Inicia", value: "start", sortable: false, align: "center" },
        { text: "Finaliza", value: "end", sortable: false, align: "center" }
      ],
      citas: [] as GetReservaCitaEventoDto[]
    };
  },
  methods: {
    loadCitas() {
      CitaEventoService.getMisCitas()
        .then(response => {
          this.citas = response;
        })
        .catch(() => {
          //
        });
    }
  },
  created() {
    this.loadCitas();
  }
});
