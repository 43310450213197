import httpClient from "../http-client";

const ENDPOINT = "/evento/cita";

export interface CreateCitaEventoDto {
  fecha: string;
}

export interface GetCitaEventoDto {
  fecha: string;
}

export interface GetReservaCitaEventoDto {
  id: number;
  horario: {
    fechaInicio: string;
    fechaFin: string;
  };
}

class CuponEventoService {
  getReservasAdmin(
    carreraID: string | number
  ): Promise<GetReservaCitaEventoDto[]> {
    return httpClient
      .get(`${ENDPOINT}/${carreraID}/reservas`)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }

  getMisCitas(): Promise<GetReservaCitaEventoDto[]> {
    return httpClient.get(`${ENDPOINT}/mis-citas`).then(response => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  getDiasDisponibles(idEvento: string | number): Promise<GetCitaEventoDto[]> {
    return httpClient
      .get(`${ENDPOINT}/${idEvento}/dias-disponibles`)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }

  getHorariosDisponibles(
    idEvento: string | number
  ): Promise<GetCitaEventoDto[]> {
    return httpClient.get(`${ENDPOINT}/${idEvento}/horarios`).then(response => {
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    });
  }

  agendar(idEvento: string | number, dto: any) {
    return httpClient.post(`${ENDPOINT}/${idEvento}/agendar`, dto);
  }

  /*
  crear(
    idEvento: string | number,
    createDto: CreateCuponEventoDto
  ): Promise<CreateCuponEventoResponseDto> {
    return httpClient
      .post(`${ENDPOINT}/${idEvento}`, createDto)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }
  */
}
export default new CuponEventoService();
